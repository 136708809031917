/* 헤더 */

.header-combination-btn {
    height: 35px;
    border: 1px solid #30A9DE;
    border-radius: 10px;
    cursor: pointer;

}
.main-color-text-btn {
    color: #30A9DE;
    padding: 0.375rem 0.75rem;
    background-color: transparent;
    border: 0px;
}
.header-cal-btn {
    height: 35px;
    border-radius: 5px;
    background-color: #30A9DE;
    color: #fff;
    cursor: pointer;
}

.header-point-value {
    margin-top: 10px;
}
.profile-container {
    border-radius: 10px;
}
.profile-name-container {
    flex-direction: column;
}
.header-logout-btn {
    border-radius: 5px;
    background-color: #30A9DE;
    color: #fff;
    cursor: pointer;
    border: 0px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    margin-top: 5px;
}
@media (max-width: 1200px) {
    .point-mobile {
        display: none;
    }
}

/* header */

.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    padding-top: 10px;
    padding-bottom: 14px;
    z-index: 1032;
}
.header-container {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.header-logo {
    height: 32px;
    margin-right: 2vw;
}
.header-left {
    max-width: 600px;
    display: flex;
    align-items: flex-end;
    font-size: 18px;
    color: #000;
}
.header-right {
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header-bottom {
    width: 100%;
    display: none;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #e6e6e6;
    padding: 10px 14px 0px;
}
.header-left-content {
    color: #000;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    position: relative;
    text-decoration: none;
    font-weight: 100;
    margin-left: 2vw;
}
.header-left-content.active {
    color:#30A9DE;
    font-weight: 600;
}

.header-left-content:hover {
    color: inherit;
    opacity: 0.7;
}
.header-left-content.active:hover {
    color: #30A9DE;
    opacity: 1;
}
.header-active-bottom-line {
    position: absolute;
    background-color: #30A9DE;
    height: 4px;
    width: 100%;
    bottom: -14px;
    left:0px;
}
.header-active-bottom-line-ra {
    position: absolute;
    background-color: #30A9DE;
    height: 4px;
    width: 100%;
    bottom: -17px;
    left:0px;
}
.header-middle-content {
    color: #000;
    text-decoration: none;
}

.header-bottom-divider {
    height: 20px;
    width: 1px;
    background: #e6e6e6;
}
.header-right-content {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    margin-right: 30px;
    color: #777;
}
.header-right-content:hover {
    color: #777;
}
.header-right-content span {
    color: #000;
    font-weight: bold;
}
.header-right-content svg {
    cursor: pointer;
}
.header-notification-alert {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
}
@media (max-width:800px) {
    .header-container {
        font-size: 14px;
    }
    .header-logo {
        height: 30px;
    }
    .header-right-content {
        margin-right: 0px;
        margin-left: 20px;
    }
}